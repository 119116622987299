import en from './locales/en.json'
import de from './locales/de.json'
import da from './locales/da.json'
import es from './locales/es.json'
import fr from './locales/fr.json'
import it from './locales/it.json'
import nl from './locales/nl.json'
import pt from './locales/pt.json'
import sl from './locales/sl.json'
import pl from './locales/pl.json'
import fi from './locales/fi.json'
import sv from './locales/sv.json'
import et from './locales/et.json'

export default defineI18nConfig(() => {
  return {
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
      en,
      de,
      da,
      es,
      fr,
      it,
      nl,
      pt,
      pl,
      sl,
      fi,
      sv,
      et,
    },
  }
})
